import React, { useState } from "react"
import styled from "styled-components"
import "@fontsource/eb-garamond"
import { StaticImage as Image } from "gatsby-plugin-image"

const Visit = () => {
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")

  const onSubmitForm = async e => {
    e.preventDefault()
    try {
      const body = { name, phone, email }
      const response = await fetch(
        "https://zajiyahcarnival.herokuapp.com/visits",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(body),
        }
      )

      window.location = "/"
    } catch (err) {
      console.error(err.message)
    }
  }
  return (
    <SectionContainer>
      <SectionWrapper>
        <ColumnOne>
          <SectionH1>BOOK A VISIT</SectionH1>
          <SectionP>
            <Contact1>
              <form onSubmit={onSubmitForm}>
                <FormWrap>
                  <label htmlFor="name">
                    <input
                      type="text"
                      placeholder="Name"
                      id="name"
                      name="name"
                      required
                      value={name}
                      onChange={e => setName(e.target.value)}
                    ></input>
                  </label>
                  <Feild2>
                    <label htmlFor="phone">
                      <input
                        type="text"
                        placeholder="Phone"
                        id="phone"
                        inputmode="numeric"
                        name="phone"
                        required
                        value={phone}
                        onChange={e => setPhone(e.target.value)}
                      ></input>
                    </label>
                  </Feild2>
                  <Feild3>
                    <label htmlFor="email">
                      <input
                        type="email"
                        placeholder="Email"
                        id="email"
                        name="email"
                        required
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                      ></input>
                    </label>
                  </Feild3>
                  <ButtonFour type="submit">Contact Me</ButtonFour>
                </FormWrap>
              </form>
            </Contact1>
          </SectionP>
        </ColumnOne>
        <ColumnTwo>
          <Image src="../../images/Section2.png" alt="Sec2" />
        </ColumnTwo>
      </SectionWrapper>
    </SectionContainer>
  )
}

export default Visit

const SectionContainer = styled.div`
  width: 100%;
  background: white;
  color: black;
  padding: 6rem 0rem;

  margin: auto;
`
const SectionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 1 1rem;
  grid-gap: 2.5rem;

  @media screen and (max-width: 960px) {
    grid-template-columns: 1fr;
  }
`
const ColumnOne = styled.div`
  padding: 0 1rem;
  align-items: center;
  @media screen and (max-width: 960px) {
    padding: 0 0rem;
  }
`
const ColumnTwo = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding: 0 1rem;

  @media screen and (max-width: 960px) {
    padding: 0 1rem;
  }
`

const SectionH1 = styled.h1`
  font-family: "League Spartan", sans-serif;
  font-size: 3.5rem;
  font-weight: 600;
  color: #545454;
  text-align: center;
  letter-spacing: 1px;

  @media screen and (max-width: 960px) {
    text-align: center;
  }
`

const Contact1 = styled.div`
  h1 {
    font-family: "Cinzel", serif;
    font-weight: 300;
    font-size: 24px;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 768px) {
    margin-left: 0;
  }
`

const FormWrap = styled.div`
  align-items: center;
  input {
    padding: 1rem 1.5rem;
    width: 400px;
    height: 20px;
    outline: none;
    border-radius: 15px;
    justify-content: center;
    align-items: center;

    border: 1px solid #fabf49;
    background: white;
    margin: 1rem 1rem;
  }

  @media screen and (max-width: 960px) {
    margin-bottom: 2rem;
  }
`
const Feild2 = styled.div`
  margin-top: -1rem;
  margin-bottom: 1rem;
`

const Feild3 = styled.div`
  margin-top: -2rem;
  margin-bottom: 1rem;
`

const ButtonFour = styled.button`
  background: #e1ae44;
  white-space: nowrap;

  padding: 12px 26px;
  color: black;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);

  font-size: 13px;
  font-family: "Encode Sans Expanded", sans-serif;
  font-weight: 600;
  font-size: 1.1rem;
  cursor: pointer;
  text-decoration: none;

  transition: 0.3s !important;
  border-radius: 15px;
  border: none;

  &:hover {
    background: white;
    color: #e1ae44;
    transform: translateY(-5px);
    box-shadow: 0px 8px 15px rgba(225, 174, 68, 0.2);
  }
`

const SectionP = styled.p`
  color: #545454;
  font-size: 1.3rem;
  text-align: center;
  font-family: "EB Garamond";
  line-height: 1.7;
  font-weight: 800;

  @media screen and (max-width: 960px) {
    text-align: center;
  }
`
